
//foster homes
const steps = document.querySelectorAll(".js-foster__target");
const first_steps = document.querySelectorAll(".p-foster__flowStep .first-step");
const second_steps = document.querySelectorAll(".p-foster__flowStep .second-step");
const flow_items = document.querySelectorAll(".p-foster__flowItem")

const wh = window.innerHeight;

window.addEventListener('scroll', (event) => {

    const sh = window.pageYOffset;

    for(let i = 0; i < steps.length; i++){
        const topPos_steps = steps[i].getBoundingClientRect().top + window.pageYOffset + 200;
        if(sh > (topPos_steps - wh)){
            steps[i].classList.add("is-show");
        }
    }

    for(let i = 0; i < flow_items.length; i++){
        const topPos_flow_items = flow_items[i].getBoundingClientRect().top + window.pageYOffset;
        if(sh > (topPos_flow_items - wh)){
            flow_items[i].classList.add("is-show");
        }
    }

});


//hotel
const points = document.querySelectorAll(".p-hotel__pointItem");

window.addEventListener('scroll', (event) => {

    const sh = window.pageYOffset;

    for(let i = 0; i < points.length; i++){
        const topPos_point = points[i].getBoundingClientRect().top + window.pageYOffset;
        if(sh > (topPos_point - wh)){
            points[i].classList.add("is-show");
        }
    }
});
