

//トップページのkvアニメーション
const top = document.getElementById("top");
const kv = document.querySelector(".p-kv");
const contents = document.querySelector(".l-contents")
const wrapper = document.querySelector(".l-wrapper")
const title = document.querySelector(".p-concept__titleWrap");
const img = document.querySelector(".p-concept__kv");
const illust = document.querySelector(".p-concept__illust");
const text = document.querySelector(".p-concept__text");
const message = document.querySelector(".p-kv__message");
const slider3 = document.querySelector(".swiper-slide.slide3");
const slider1 = document.querySelector(".swiper-slide.slide1");
const scrollDown = document.querySelectorAll(".c-scrollDown");
const logo = document.querySelector(".l-header__logo");
const html = document.querySelector("html");
const body = document.querySelector(".l-main");
const loader = document.querySelector(".l-loading");


const urlHash = location.hash
console.log(urlHash)

if(top != null){
window.addEventListener('load',function(){
    html.classList.add("no-scroll");
    if((urlHash != "#access") || (urlHash != "#top")){
        setTimeout(function start(){
            html.classList.remove("no-scroll");
            wrapper.classList.add("start");
            loader.classList.add("start");
        }, 2000);
    }
    else{
        html.classList.remove("no-scroll");
        wrapper.classList.add("start");
        loader.classList.add("start");
    }
});

if((urlHash == "#access") || (urlHash == "#top")){
    contents.classList.add("is-active");
    kv.classList.add("is-active");
    wrapper.classList.add("is-active");
    title.classList.add("is-active");
    img.classList.add("is-active");
    text.classList.add("is-active");
    message.classList.add("is-active");
    slider3.classList.add("is-active");
    slider1.classList.add("is-active");
    loader.classList.add("end");
    wrapper.classList.remove("start");
    logo.classList.add("is-show");
}

window.addEventListener("scroll", function(){

    const sh = window.pageYOffset;

    kv.classList.add("is-active");
    wrapper.classList.add("is-active");
    title.classList.add("is-active");
    img.classList.add("is-active");
    text.classList.add("is-active");
    message.classList.add("is-active");
    slider3.classList.add("is-active");
    slider1.classList.add("is-active");
    loader.classList.add("end");
    wrapper.classList.remove("start");
    logo.classList.add("is-show");
    
        for(let i = 0; i < scrollDown.length; i++){
            scrollDown[i].classList.add("no-show");
        }
        setTimeout(function start(){
        contents.classList.add("is-active");
        }, 1000)
        

        if(!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
            illust.classList.add("is-active");
        }

        const topPos_illust = illust.offsetTop;
        if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
            if(sh > (topPos_illust + 200)){
                illust.classList.add("is-active");
            }
        }
    

});

//犬の画像フェードイン,コンテンツ背景色のアニメーション
const wh = window.innerHeight;

const dogImg = document.querySelector(".p-concept__img");
const visionLink = document.querySelector(".p-concept__link");
const foster = document.querySelector(".p-foster");
const hotel = document.querySelector(".p-hotel");
const dogRun = document.querySelector(".p-dogRun");
const texts = document.querySelectorAll(".p-concept__text p");

window.addEventListener('scroll', (event) => {
    
    const sh = window.pageYOffset;
    const topPos_dogImg = dogImg.getBoundingClientRect().top + window.pageYOffset;
    const topPos_visionLink = visionLink.getBoundingClientRect().top + window.pageYOffset;
    const topPos_foster = foster.getBoundingClientRect().top + window.pageYOffset;
    const topPos_hotel = hotel.getBoundingClientRect().top + window.pageYOffset;
    const topPos_dogRun = dogRun.getBoundingClientRect().top + window.pageYOffset;
    const img = document.querySelector(".l-header__logo img");
    
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
        if(sh > wh){
            logo.classList.add("is-black");
            logo.classList.add("is-active");
            img.setAttribute('src', '/assets/img/top/logo_header_black.svg')
            
        }
        else{
            logo.classList.remove("is-black");
            logo.classList.remove("is-active");
            img.setAttribute('src', '/assets/img/top/logo_header.svg')
    }}

    if(sh > (topPos_dogImg - wh)){
        dogImg.classList.add("is-show");
    }
    if(sh > (topPos_visionLink - wh)){
        visionLink.classList.add("is-show");
    }
    if(sh > (topPos_foster - wh)){
        foster.classList.add("is-show");
    };
    if(sh > (topPos_hotel - wh)){
        hotel.classList.add("is-show");
    };
    if(sh > (topPos_dogRun - wh)){
        dogRun.classList.add("is-show");
    };

    for(let i = 0; i < texts.length; i++){
        const topPos_text = texts[i].getBoundingClientRect().top + window.pageYOffset;
        if(sh > (topPos_text - wh)){
            texts[i].classList.add("is-fade");
        }
    }
    
});

};






