// import top_action from './top';
import Swiper from 'swiper/bundle';

const swiper =  {
    effect: 'fade',
    loop: false,
    autoplay: {
        delay: 3500,
        stopOnLastSlide: 'true',
    },
    speed: 1000,
    on: {
        autoplayStop: function(){
            top_action();
        }
    }
};


const urlHash = location.hash
if((urlHash != "#access") && (urlHash != "#top")){
    window.addEventListener('load',function(){
        setTimeout(function start(){
            new Swiper('.swiper.slider1', swiper)
        }, 2000)

    });
}else{
    new Swiper('.swiper.slider1', swiper)
}

   
    const slider2 = new Swiper('.swiper.slider2', {
        loop: true,
        direction: 'horizontal',
        slidesPerView: 3.5,
        slidesPerGroup: 1,
        speed: 5000,
        spaceBetween: 20,
        autoplay: true,
        allowTouchMove: false,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,

        },
        breakpoints:{
            768:{
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });


    const slider3 = new Swiper('.swiper.slider3', {
        loop: true,
        direction: 'horizontal',
        slidesPerView: 3.5,
        slidesPerGroup: 1,
        speed: 5000,
        spaceBetween: 20,
        autoplay: true,
        allowTouchMove: false,
        autoplay: {
            reverseDirection: true,
            delay: 0,
            disableOnInteraction: false,
        },
        breakpoints:{
            768:{
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },

    });




function top_action(){
    const kv = document.querySelector(".p-kv");
    const contents = document.querySelector(".l-contents")
    const wrapper = document.querySelector(".l-wrapper")
    const title = document.querySelector(".p-concept__titleWrap");
    const img = document.querySelector(".p-concept__kv");
    const illust = document.querySelector(".p-concept__illust");
    const text = document.querySelector(".p-concept__text");
    const message = document.querySelector(".p-kv__message");
    const slider3 = document.querySelector(".swiper-slide.slide3");
    const scrollDown = document.querySelectorAll(".c-scrollDown");
    const logo = document.querySelector(".l-header__logo");
    
    
        kv.classList.add("is-active");
        if(!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
            contents.classList.add("is-active");
            wrapper.classList.add("is-active");
            title.classList.add("is-active");
            img.classList.add("is-active");
            illust.classList.add("is-active");
            text.classList.add("is-active");
        }
        message.classList.add("is-active");
        slider3.classList.add("is-active");
        logo.classList.add("is-show");
        
        for(let i = 0; i < scrollDown.length; i++){
            scrollDown[i].classList.add("no-show");
        }
        
};



