import SmoothScroll from 'smooth-scroll';

const scroll = new SmoothScroll('a[href*="#"]',{
    speed: 400
});


// //accessの時のみ例外
// const urlHash = location.hash
// if(urlHash == "#access"){
//     const wh = window.innerHeight;
//     const access = document.getElementById("access");
//     const topPos_access = access.getBoundingClientRect().top;

//     setTimeout(function start(){
//         window.scrollTo({
//             top: topPos_access,
//             behavior: 'smooth'
//         });
//     }, 1000)
// }
